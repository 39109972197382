import {HttpBackend, HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {FilterEntreprise} from '../../model/filter/filter-entreprise.model';
import {Entreprise} from '../../model/entreprise/entreprise.model';
import {FilterIntervenant} from '../../model/filter/filter-intervenant.model';
import {Observable} from 'rxjs';
import {PageRequest} from 'src/app/model/request/pageRequest';
import {AbstractWebservice} from "./abstract.webservice";
import {UrlBuilder} from "../../utils/helpers/url-builder.helper";

@Injectable({
  providedIn: 'root'
})
export class EntrepriseWebservice extends AbstractWebservice {
  entity = 'entreprise';

  constructor(protected readonly httpClient: HttpClient,
              protected readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getEntrepriseWithFilter(filterEntreprise: FilterEntreprise, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParam('list')
      .addPagination(pageRequest)
      .build();
    return this.post(url, filterEntreprise);
  }

  getEntrepriseDetail(idEntreprise: number) {
    return this.get(`${this.root}/detail/${idEntreprise}`);
  }

  getEntreprise(idEntreprise: number): Observable<Entreprise> {
    return this.get<Entreprise>(`${this.root}/${idEntreprise}`);
  }

  getAllEntreprise(name?: string) {
    const url = new UrlBuilder(this.root)
      .addQueryParam('entrepriseName', name)
      .build();
    return this.get(url);
  }

  getIntervenantEntreprise(filter: FilterIntervenant, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParam('intervenants')
      .addPagination(pageRequest)
      .build();
    return this.post(url, filter);
  }

  getAllEntrepriseName() {
    return this.get(`${this.root}/allName`);
  }

  postSaveEntreprise(entreprise: Entreprise) {
    return this.post(`${this.root}/save`, entreprise);
  }

  putSaveEntreprise(entreprise: Entreprise) {
    return this.put(`${this.root}`, entreprise);
  }

  deleteEntreprise(id: number) {
    return this.delete(`${this.root}/${id}`);
  }

  checkExisting(name) {
    return this.get(`${this.root}/checkName/${name}`);
  }
}
