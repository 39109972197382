import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Role} from 'src/app/model/role/role.model';
import {Module} from 'src/app/model/role/module.model';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class RoleWebservice extends AbstractWebservice {
  entity = `roles`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getAllRoles() {
    return this.get<Role[]>(`${this.root}`);
  }

  updateRoleModules(idRole: number, listOfModules: Module[]) {
    return this.put(`${this.root}/${idRole}/modules`, listOfModules);
  }

  createRole(role: Role) {
    return this.post(`${this.root}`, role);
  }

  deleteRole(idRole: number) {
    return this.delete(`${this.root}/${idRole}`);
  }
}
