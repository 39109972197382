import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Ensemble} from '../../model/event/ensemble.model';
import {FilterYoungJob} from '../../model/filter/filter-young-job.model';
import {Observable} from 'rxjs';
import {PageRequest} from 'src/app/model/request/pageRequest';
import {ProjectYoungAndPresencesDTO} from '../../model/dto/ensemble.dto';
import {TreeItemNode} from 'src/app/model/event/treeData/tree-item-node.model';
import {Paged} from 'src/app/model/response/paged.model';
import {AbstractWebservice} from "./abstract.webservice";
import {UrlBuilder} from "../../utils/helpers/url-builder.helper";

@Injectable({
  providedIn: 'root'
})
export class EnsembleWebservice extends AbstractWebservice {
  entity = 'ensemble';

  constructor(protected readonly httpClient: HttpClient,
              protected readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }
  getAllEnsembleTree() {
    return this.get(`${this.root}/tree`);
  }

  getEnsembleTree(pageRequest: PageRequest, allEnsemble: boolean = true) {
    const url = new UrlBuilder(this.root)
      .addPathParams('tree', allEnsemble)
      .addPagination(pageRequest)
      .build();

    return this.get<Paged<TreeItemNode>>(url);
  }

  getEnsembleById(idEnsemble: number) {
    return this.get(`${this.root}/${idEnsemble}`);
  }

  ensembleHaveChildById(idEnsemble: number) {
    return this.get(`${this.root}/childs/${idEnsemble}`);
  }

  ensembleHaveLinkEventById(idEnsemble: number) {
    return this.get(`${this.root}/events/${idEnsemble}`);
  }

  createEnsemble(ensemble: Ensemble, pageRequest: PageRequest): Observable<Paged<TreeItemNode>> {
    const url = new UrlBuilder(this.root)
      .addPathParam('create')
      .addPagination(pageRequest)
      .build();
    return this.post<Paged<TreeItemNode>>(url, ensemble);
  }

  editEnsemble(ensemble: Ensemble, pageRequest: PageRequest): Observable<Paged<TreeItemNode>> {
    const url = new UrlBuilder(this.root)
      .addPathParam('edit')
      .addPagination(pageRequest)
      .build();

    return this.put<Paged<TreeItemNode>>(url, ensemble);
  }

  deleteEnsembleById(
    idEnsemble: number,
    pageRequest: PageRequest
  ): Observable<Paged<TreeItemNode>> {
    return this.http.delete<Paged<TreeItemNode>>(
      `${this.root}/delete/${idEnsemble}`,
      PageRequest.toQueryParams(pageRequest)
    );
  }

  getJobEnsemble(filter: FilterYoungJob, pageRequest: PageRequest) {
    const url = new UrlBuilder(this.root)
      .addPathParam('jdlv')
      .addPagination(pageRequest)
      .build();
    return this.post(url, filter);
  }

  getEnsembleSummaryById(idEnsemble: number) {
    return this.get(`${this.root}/summary/${idEnsemble}`);
  }

  getSubDTOById(idEnsemble: number) {
    return this.get(`${this.root}/sub/${idEnsemble}`);
  }

  getSubEventDTO(listEventId: number[]) {
    return this.post(`${this.root}/events`, listEventId);
  }

  getPresenceYoungs(listEventId: number[]): Observable<ProjectYoungAndPresencesDTO> {
    return this.post<ProjectYoungAndPresencesDTO>(`${this.root}/presenceYoungs`, listEventId);
  }

  getAllEventsFromEnsemble(idEnsemble: number) {
    return this.get(`${this.root}/${idEnsemble}/events`);
  }
}
