import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Module} from 'src/app/model/role/module.model';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class ModuleWebservice extends AbstractWebservice {
  entity = `modules`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getAllModules() {
    return this.get<Module[]>(`${this.root}`);
  }

  deleteModule(idModule: number) {
    return this.delete(`${this.root}/${idModule}`);
  }
}
