import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {AlertService} from 'src/app/services/alert.service';
import {MatDialog, MatDialogActions, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {Destroyed} from '../../directives/destroyed.directive';
import {TypeStructureEntrepriseEnum} from 'src/app/model/enums/type-structure-entreprise.enum';
import {FormatService} from 'src/app/services/format.service';
import {Observable} from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {EntrepriseWebservice} from 'src/app/services/webservices/entreprise.webservice';
import {Entreprise} from 'src/app/model/entreprise/entreprise.model';
import {DialogDefaultComponent} from '../dialog-default/dialog-default.component';
import {EntrepriseDTO} from 'src/app/model/dto/entreprise.dto';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatOptionModule} from '@angular/material/core';
import {AsyncPipe, NgClass, NgFor, NgIf} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {getEnumKeysAsNumber} from 'src/app/utils/utils.static';

@Component({
  selector: 'app-dialog-add-structure',
  templateUrl: './dialog-add-structure.component.html',
  styleUrls: ['./dialog-add-structure.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatDialogTitle,
    MatSelectModule,
    NgClass,
    NgFor,
    MatOptionModule,
    NgIf,
    MatAutocompleteModule,
    MatDialogActions,
    MatButtonModule,
    AsyncPipe
  ]
})
export class DialogAddStructureComponent extends Destroyed implements OnInit, OnDestroy {
  entrepriseFormGroup: UntypedFormGroup;
  structureForSelect: StructureSelect[];
  filteredNameMulti: Observable<string[]>;

  public allEntrepriseName = [];
  entrepriseExisting: unknown = false;

  entrepriseName: string;
  isSaveBlocked: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogAddStructureComponent>,
    private readonly fb: UntypedFormBuilder,
    private readonly alertService: AlertService,
    private readonly formatService: FormatService,
    private readonly entrepriseWebService: EntrepriseWebservice,
    private readonly dialog: MatDialog
  ) {
    super();
  }

  get fEntreprise() {
    return this.entrepriseFormGroup.controls;
  }

  ngOnInit() {
    this.entrepriseFormGroup = this.fb.group({
      choixTypeStructure: [null, Validators.required],
      entrepriseName: [null, Validators.required]
    });

    if (this.entrepriseName) {
      this.fEntreprise.entrepriseName.setValue(this.entrepriseName);
    }

    this.initTypeStructureSelect();
    this.initEntreprise();

    this.entrepriseFormGroup.controls.entrepriseName.valueChanges
      .pipe(this.untilDestroyed())
      .subscribe(() => {
        this.checkExistingEntrepriseName();
      });
  }

  initTypeStructureSelect() {
    this.structureForSelect = [];
    getEnumKeysAsNumber(TypeStructureEntrepriseEnum).forEach((i) =>
      this.structureForSelect.push({id: i, name: TypeStructureEntrepriseEnum.format(i)})
    );
  }

  onValidateChoice(value) {
    this.dialogRef.close(value);
  }

  ngOnDestroy() {
    this.alertService.clear();
  }

  checkExistingEntrepriseName() {
    if (this.entrepriseFormGroup.controls.entrepriseName.value) {
      if (this.entrepriseFormGroup.controls.entrepriseName.value.length > 2) {
        this.entrepriseWebService
          .checkExisting(this.entrepriseFormGroup.controls.entrepriseName.value)
          .pipe(debounceTime(1000))
          .subscribe((res) => {
            this.entrepriseExisting = res;
          });
      }
    }
  }

  dismiss() {
    this.dialogRef.close();
  }

  checkExistingEntrepriseBeforeSave() {
    this.isSaveBlocked = true;
    if (this.entrepriseExisting) {
      const title = 'Entreprise déjà existante';
      const message = `L'entreprise ${this.fEntreprise.entrepriseName.value} existe déjà dans l'application. Êtes-vous sûr de vouloir en créer une nouvelle ?`;

      const dialogRef = this.dialog.open(DialogDefaultComponent);

      dialogRef.componentInstance.title = title;
      dialogRef.componentInstance.message = message;

      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe((result) => {
          if (result) {
            this.saveEntreprise();
          } else {
            this.isSaveBlocked = false;
          }
        });
    } else {
      this.saveEntreprise();
    }
  }

  saveEntreprise() {
    if (this.entrepriseFormGroup.valid) {
      const entrepriseToAdd = new Entreprise();
      entrepriseToAdd.id = 0;
      entrepriseToAdd.dateBegin = new Date();
      entrepriseToAdd.nom = this.fEntreprise.entrepriseName.value;
      entrepriseToAdd.entreprisePartenaire = false;

      this.entrepriseWebService
        .postSaveEntreprise(entrepriseToAdd)
        .pipe(this.untilDestroyed())
        .subscribe(
          (data: Entreprise) => {
            this.dialogRef.close(EntrepriseDTO.toDto(data));
          },
          (error) => {
            this.isSaveBlocked = false;
          }
        );
    } else {
      this.alertService.error('Informations manquantes');
      this.isSaveBlocked = false;
    }
  }

  private initEntreprise() {
    this.entrepriseWebService.getAllEntrepriseName().subscribe({
      next: (res: string[]) => {
        this.allEntrepriseName = res;
      },
      error: () => {},
      complete: () => {
        this.entrepriseFormGroup.updateValueAndValidity();
      }
    });

    this.filteredNameMulti = this.entrepriseFormGroup.controls.entrepriseName.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterName(value))
    );
  }

  private _filterName(value: string) {
    if (typeof value === 'string' && this.allEntrepriseName) {
      const filterValue = value.toLowerCase();
      return this.allEntrepriseName.filter((name) => name.toLowerCase().includes(filterValue));
    }
    return [];
  }
}

interface StructureSelect {
  id: number;
  name: string;
}
