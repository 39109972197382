import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {VisitCreationDTO} from 'src/app/model/dto/visit-creation-infos.dto';
import {VisitForUpdateDTO} from 'src/app/model/dto/visit-dto/visit-update.dto';
import {PageRequest} from 'src/app/model/request/pageRequest';
import {environment} from 'src/environments/environment';
import {getMobileToken} from 'src/app/utils/utils.static';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class FamilyVisitWebService extends AbstractWebservice {
   entity = `visitFamily`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getYoungFamilyVisits(listOfIdYoung: number[], pageRequest: PageRequest, onlySigned: boolean) {
    const body = {
      pageNumber: pageRequest.pageNumber,
      pageSize: pageRequest.pageSize,
      listOfIdYoung: listOfIdYoung,
      onlySigned: onlySigned
    };
    return this.post(`${this.root}/getYoungsFamilyVisits`, body);
  }

  getFamilyVisit(visitId: number) {
    return this.get(`${this.root}/getFamilyVisit/${visitId}`);
  }

  createVisitFamily(data: VisitCreationDTO) {
    return this.post(`${this.root}/createVisit`, data);
  }

  startVisit(visitId: number) {
    return this.get(`${this.root}/startVisit/${visitId}`);
  }

  signVisit(visitId: number) {
    return this.get(`${this.root}/signVisit/${visitId}`);
  }

  updateVisit(visit: VisitForUpdateDTO) {
    return this.put(`${this.root}/update`, visit);
  }

  updateMinimalVisit(visit: VisitCreationDTO, visitId: number) {
    return this.put(`${this.root}/updateMinimal/${visitId}`, visit);
  }

  deleteVisit(visitId: number) {
    return this.delete(`${this.root}/${visitId}`);
  }
}
