import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {OccurrenceDTO} from 'src/app/model/dto/occurrence.dto';
import {OccurrenceChangeTimeDTO} from '../../model/dto/occurence-change-time.dto';
import {OccurrenceUpdatedDto} from '../../model/dto/occurrence-updated.dto';
import {Observable} from 'rxjs';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class OccurrenceWebservice extends AbstractWebservice {
   entity = `occurrences`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  putChangeStatus(occu: OccurrenceDTO) {
    return this.put(`${this.root}/status`, occu);
  }

  setNombreParticipant(id: number, nbParticipantPresent: number) {
    return this.get(`${this.root}/participant/${id}/${nbParticipantPresent}`);
  }

  putChangeTime(id: number, time: number): Observable<OccurrenceUpdatedDto> {
    const data: OccurrenceChangeTimeDTO = new OccurrenceChangeTimeDTO(id, time);
    return this.put<OccurrenceUpdatedDto>(`${this.root}/time`, data);
  }

  cancelPresence(id: number): Observable<void> {
    return this.get<void>(`${this.root}/${id}/reset-presence`);
  }
}
