import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {StatusEnum} from 'src/app/model/enums/status.enum';
import {DialogService} from 'src/app/services/dialog.service';
import {ExportWebservice} from 'src/app/services/webservices/export.webservice';
import {FamilyVisitWebService} from 'src/app/services/webservices/familyvisit.webservice';
import {Destroyed} from '../../../directives/destroyed.directive';
import {ModuleSource, onlyAdmin} from '../../../../../model/enums/source/module-source.enum';
import {HasModuleDirective} from '../../../directives/has-module.directive';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-download-visit-btn-renderer',
  templateUrl: './download-visit-btn-renderer.component.html',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, MatIconModule, HasModuleDirective]
})
export class DownloadVisitBtnRendererComponent extends Destroyed {
  visitId: number;
  visitStatus: StatusEnum;
  context;
  public readonly canShowBtn = [
    ModuleSource.ADMINISTRATION,
    ModuleSource.ADMIN_NIV_2,
    ModuleSource.ADMIN_NIV_1,
    ModuleSource.VISUALISATION_VISITE_FAMILLE,
    ModuleSource.MODIFICATION_VISITE_FAMILLE
  ];
  public readonly onlyAdmin = onlyAdmin;

  constructor(
    private readonly router: Router,
    private readonly familyVisitWebService: FamilyVisitWebService,
    private readonly dialogService: DialogService,
    private readonly exportWebService: ExportWebservice
  ) {
    super();
  }

  agInit(params): void {
    this.visitId = params.data.id;
    this.context = params.context;
    this.visitStatus = params.visitStatus;
  }

  onShowVisitBtnClick(): void {
    this.router.navigate(['/family-setting/visit-family', this.visitId]);
  }

  onDeleteBtnClick(): void {
    const title = 'Supprimer le rendez-vous';
    const content = 'Êtes vous sur de vouloir supprimer ce rendez-vous';
    this.dialogService.confirm(title, content, 'Valider', 'Annuler').then((value: boolean) => {
      if (value) {
        this.familyVisitWebService
          .deleteVisit(this.visitId)
          .pipe(this.untilDestroyed())
          .subscribe(() => this.context.componentParent.removeVisitYoung(this.visitId));
      }
    });
  }

  onDownloadBtnClick(): void {
    this.exportWebService
      .printPDFVisite(this.visitId)
      .pipe(this.untilDestroyed())
      .subscribe(() => null);
  }

  isDownloadAllowed(): boolean {
    return this.visitStatus === StatusEnum.VALIDATED;
  }
}
