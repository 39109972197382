import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {UserRoleDTO} from '../../model/dto/user-role.dto';
import {UserPerRoleDTO} from '../../model/dto/user-per-role.dto';
import {RoleDTO} from '../../model/dto/role.dto';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class RoleUserWebservice extends AbstractWebservice {
  entity = `roleUsers`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  /**
   * Add userRole in DB, no delete
   * @param listOfRole
   */
  postRoleUser(listOfRole: UserRoleDTO[]) {
    return this.post(`${this.root}`, listOfRole);
  }

  /**
   * Delete roleUser by id
   * @param idRoleUser
   */
  deleteRoleUser(idRoleUser: number) {
    return this.delete(`${this.root}/${idRoleUser}`);
  }

  /////////////////////// USER ////////////////////////////////////

  getRoleUserByUserId(idUser: number): Observable<UserPerRoleDTO[]> {
    return this.get(`${this.root}/user/${idUser}`)
      .pipe(map((value) => value as UserPerRoleDTO[]));
  }

  getAllRoleUser() {
    return this.get(`${this.root}/user/allRole`).pipe(map((value) => value as RoleDTO[]));
  }
}
