import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Timesheet} from 'src/app/model/event/timesheet.model';
import {environment} from 'src/environments/environment';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class TimesheetWebservice extends AbstractWebservice{
  entity = `timesheets`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  putUpdateTimesheetList(list: Timesheet[]) {
    return this.put(`${this.root}`, list);
  }
}
